.hundred-button {
    border: 1px solid var(--buttonColor);
    color: var(--text);
    margin: 0 5px;
    display: flex;
    height: 40px;
    min-width: 110px;
    max-width: 210px;
    border-radius: 20px;
    cursor: pointer;
    padding: 0 0px;
    transition: background-color 0.2s ease-out;
    position: relative;
    align-items: center;
    justify-content: center;
}

.hundred-button:hover {
    background-color: var(--buttonColor);
    color: var(--buttonHover2);
}

.hundred-button .hundred-button-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.hundred-button .hundred-button-content .hundred-name {
    padding: 0 8px;
}

.hundred-button .hundred-button-content .arrow {
    font-size: 0.7rem;
}

.hundred-button .hundred-logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--text);
}
