.sideMenu{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 9000;
}

.sideMenu .sideMenu-overlay{
    float: left;
    display: block;
    height: 100%;
    width: 100%;
    background-color: var(--overlayBackground);
}

.sideMenu .sideMenu-wrapper{
    display: flex;
    flex-direction: column;
    min-width: 220px;
    max-width: 300px;
    height: 100%;
    background-color: var(--navBackground);
    color: var(--text);
    position: fixed;
    right: 0;
    margin: 0;
    padding: 80px 10px 10px 10px;
    transition: all .2s ease-in-out;
}

.sideMenu .close{
    border: 1px solid rgba(243, 243, 243, 0);
    display: block;
    width:30px;
    height: 30px;
    float: right;
    position: absolute;
    padding: 0;
    margin: 0;
    transition: transform 0.2s ease-in-out,
                border-color 0.2s ease-in-out 0.1s,
                background-color 0.2s ease-out;                
    cursor: pointer;
    user-select: none;
    border-radius: 50%;
    top:15px;
    right: 10px;
}

.sideMenu .close:hover{
    border-color: rgba(243, 243, 243, 1);
    transform: rotate(90deg);
    background-color: #444;
}

.sideMenu .close::before{
    content: "";
    display: block;
    width: 60%;
    height: 3px;
    background-color: #f3f3f3f3;
    position: absolute;
    top:50%;
    left: 49%;
    transform: translate(-50%, -50%)  rotate(45deg);
    transition: all 0.2s ease-out;
}

.sideMenu .close::after{
    content: "";
    display: block;
    width: 60%;
    height: 3px;
    background-color: #f3f3f3f3;
    position: absolute;
    top:50%;
    left: 49%;
    transform: translate(-50%, -50%)  rotate(-45deg);
    transition: all 0.2s ease-out;
}

.sideMenu .close:active{
    background-color: #f3f3f3f3;
}

.sideMenu .close:active::after, .sideMenu .close:active::before{
    background-color: #444;
}

.side-menu-hidden{
    display: none;
}

