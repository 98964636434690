.general-details-section{
    position: relative;
    width:100%;
    z-index: 1;
    
}

.general-details-section .section-content{
    padding: 1rem .5rem;
}

.general-details-section::after{
    content: "";
    position: absolute;
    width: 100%;
    /* height: 85%; */
    height: 100%;
    background-color: var(--sectionBackground);
    top: 0;
    left: 0;
    z-index: -1;
}

.general-details-item{
    background-color: var(--boxBackground);
    margin: 2rem 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    color: var(--secondaryText);
    height: 380px;
    width: 100%;
    max-width: 300px;
}

.general-details-item-title{
    width: 100%;
    padding: 1rem 2rem;
    text-align: left;
    display: flex;
    align-items: center;
}

.general-details-item .general-details-item-icon {
    display: block;
    width: 24px;
    height: 24px;
    border: 2px solid #2953FF;
    border-radius: 50%;
}

.general-details-item .general-details-item-title-text{
    font-size: 1.5rem;
    margin-left: 10px;
    letter-spacing: 1px;
}

.general-details-item .general-details-item-content{
    padding: 0 2rem;    
    border-radius: 0px 0px 4px 4px;
    text-align: left;
}

.general-details-item .general-details-item-content .general-details-item-content-item{
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 16px 0px 16px 0px;
    min-height: 80px;
}

.general-details-item .general-details-item-content .general-details-item-content-item:not(:last-child){
    border-bottom: 1px solid var(--borderBottom);
}

.general-details-item .general-details-item-content .general-details-item-content-item label{
    opacity: 0.7;
    font-size: 1rem;
    position: relative;
}

.general-details-item .general-details-item-content .general-details-item-content-item span{
    font-size: 1.2rem;
    font-weight: bold;
    padding-top: .5rem;
}

.general-details-item .general-details-item-content .general-details-item-content-item span{
    font-size: 1.5rem;
    font-weight: bold;
}

.general-details-item .general-details-item-content .general-details-item-content-item .center{
    text-align: center;
    font-size: 1.6rem;
}

.general-details-item .general-details-item-content .general-details-item-content-item .value-details{
    font-size: 0.8rem;
    opacity: 0.7;
}

.general-details-item-content-item-pointer > label{
    cursor: pointer;
}


.info-circle{
    margin-left: 5px;
}

@media (max-width: 274px){
    .general-details-item{
        height: 415px;
    }
}

@media (min-width: 670px){
    .general-details-section .section-content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .general-details-item{
        background-color: var(--boxBackground);
        margin: 2rem 1rem;
        border-radius: 5px;
        color: var(--secondaryText);
        height: 380px;
        width: 280px;
    }

    .general-details-item:last-child{
        margin: 0rem 1rem;
    }

    .general-details-section::after{
        height: 80%;
    }
}

@media (min-width: 998px){
    
    .general-details-section::after{
        /* height: 65%; */
        height: 100%;
    }
}